/*
 * @Author: Musa Tabitay
 * @Date: 2022-04-09 15:58:49
 * @LastEditTime: 2022-04-12 23:32:11
 */

import request from '@/utils/request'

/**
 * 微信支付
 * @param {支付请求参数} data
 * @returns
 */
export const pay = data => {
  return request({
    method: 'POST',
    url: '/v2/wxpay/create_order',
    data
  })
}

/**
 * 微信支付回调API
 * @param {微信支付回调} data
 * @returns
 */
export const payResult = data => {
  return request({
    method: 'POST',
    url: '/v2/wxpay/payState',
    data
  })
}

/**
  * 获取频道的文章列表
 */
export const getArticles = data => {
  return request({
    method: 'GET',
    url: `/v2/kino/getAllVideo?vid=${data.vid}`
  })
}

/**
  * 获取文章详情
*/
export const getArticleById = id => {
  return request({
    method: 'GET',
    url: `/v2/kino/video_detail/${id}`
  })
}
