import request from '@/utils/request'

/**
 * 创建vip会员
 */
export const createAppreciates = ({
  memberName,
  price,
  type = 'vip',
  openId,
  memberId
}) => {
  return request({
    url: '/v2/appreciates/create',
    method: 'POST',
    data: {
      member_name: memberName,
      price,
      type,
      openId,
      member_id: memberId
    }
  })
}

/**
 * 微信支付成功/失败状态
 */
export const orderPayStatus = (payState, data) => {
  return request({
    url: '/v2/appreciates/payState',
    method: 'POST',
    data: {
      payState,
      member_name: data.memberName,
      member_id: data.memberId,
      price: data.price,
      order_number: data.orderNumber,
      limited_time: data.limitedTime
    }
  })
}

/**
  * 赞赏列表
  */
export const getAppreciates = (memberId) => {
  return request({
    url: `/v2/appreciates/list?status=1&member_id=${memberId}`,
    method: 'GET'
  })
}
