import request from '@/utils/request'

/**
 * 微信支付
 * @param {支付请求参数} data
 * @returns
 */
export const getUrl = ({ vid, url }) => {
  return request({
    method: 'GET',
    url: '/v2/video/tx_video',
    params: { vid, url }
  })
}
